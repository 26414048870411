<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12">
        <h1>Tracks</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <label for="text-search" class="mr-3">Search</label>
        <b-form-input id="text-search" v-model="filter.q" @keydown.enter="setSearch"></b-form-input>
        <p class="mt-2 mb-0">You can search for Artist, Title, ReportingCode en ISRC.</p>
      </b-col>

      <b-col cols="4" style="padding-top: 1.75em;">
        <b-button variant="primary" class="mr-2" style="width: 45%;" v-on:click.prevent="setSearch">Search</b-button>
        <b-button variant="light" style="width: 45%;" v-on:click.prevent="resetSearch">Reset</b-button>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <track-list :filter="activeFilter" />
      </b-col>
    </b-row>  
  </b-container>
</template>

<script>

  export default {
    name: 'Tracks',
    components: {
      'track-list': () => import('@/components/TrackList.vue'),
    },
    data () {
      return {
        labelOptions: [],
        filter: {
          q: '',
          LabelID: '',
        },
        activeFilter: {
          q: '',
          LabelID: '',
        },
      }
    },
    methods: {
      setSearch () {
        this.activeFilter.q = this.filter.q 
        this.activeFilter.ReleaseDate = this.filter.ReleaseDate

        if (this.filter.LabelID != null)
          this.activeFilter.LabelID = this.filter.LabelID.LabelID
        else
          this.activeFilter.LabelID = ''

        if (this.filter.DeliveryStatusID != null)
          this.activeFilter.DeliveryStatusID = this.filter.DeliveryStatusID.code
        else 
          this.activeFilter.DeliveryStatusID = ''
      },
      resetSearch() {
        this.filter.q = ''
        this.filter.LabelID = ''
        
        this.setSearch()
      }
    },
    mounted () {
      this.$root.$data.crumbs = [
        {to: { name: 'track-search' }, text: 'Tracks'}
      ]
    }
  }
</script>

<style scoped>
  label {
    font-weight: bold;
  }

  v-select {
    height: calc(1.5em + 0.75rem + 2px);
  }

  .inline {
    width: auto;
    display: inline-block;
  }
</style>